import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import { initiate, checkUserRole } from './store/actions';
import SelectModal from './views/Modal';
import PAGE_PATH from './consts/pathMatch';
import './App.scss';
import LOCAL_KEY from 'consts/commons';

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const ForgetPassword = React.lazy(() => import('./views/Pages/ForgetPassword'));
const ResetPassword = React.lazy(() => import('./views/Pages/ResetPassword'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
const PageMessage = React.lazy(() => import('./views/Pages/PageMessage'));

class App extends Component {
  componentDidMount() {
    this.props.initiate();
    const token = localStorage.getItem(LOCAL_KEY.TOKEN);

    if (token) {
      this.props.checkUserRole();
    } else {
      const currentPath = window.location.pathname;
      if (PAGE_PATH.indexOf(currentPath) === -1 && currentPath !== '/login') {
        window.location.href = '/login';
      }
    }
  }

  componentDidUpdate(preProps) {
    console.log({ ...this.props });
    if (preProps.ready !== this.props.ready) {
      console.log('ready');
    }
  }

  render() {
    return (
      <>
        <BrowserRouter>
          <React.Suspense fallback={null}>
            <SelectModal />
          </React.Suspense>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route
                exact
                path="/login"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
              <Route
                exact
                path="/register"
                name="Register Page"
                render={(props) => <Register {...props} />}
              />
              <Route
                exact
                path="/password/forgot"
                name="Forgot Password Page"
                render={(props) => <ForgetPassword {...props} />}
              />
              <Route
                exact
                path="/password/reset"
                name="Reset Password Page"
                render={(props) => <ResetPassword {...props} />}
              />
              <Route
                exact
                path="/password/reset/invalid-link"
                name="Message Page"
                render={(props) => (
                  <PageMessage
                    {...props}
                    title="Whoops!"
                    message="Your password reset link has expired or already used."
                  />
                )}
              />
              <Route
                exact
                path="/404"
                name="Page 404"
                render={(props) => <Page404 {...props} />}
              />
              <Route
                exact
                path="/500"
                name="Page 500"
                render={(props) => <Page500 {...props} />}
              />
              <Route
                path="/"
                name="Home"
                render={(props) => <DefaultLayout {...props} />}
              />
            </Switch>
          </React.Suspense>
        </BrowserRouter>
        <ToastContainer />
      </>
    );
  }
}

const globalData = ({ ready }) => ({
  ready,
});

const mapActionToProps = {
  initiate,
  checkUserRole,
};

export default connect(globalData, mapActionToProps)(App);
