import axios from 'axios';
import PAGE_PATH from '../consts/pathMatch';
import { toast } from 'react-toastify';
import LOCAL_KEY from 'consts/commons';

axios.interceptors.request.use(function (config) {
  if (config.pure) {
    return { ...config };
  }
  const token = localStorage.getItem(LOCAL_KEY.TOKEN);
  if (token) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: 'Bearer ' + token,
      },
    };
  }
  return config;
});

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    if (error && error.response && error.response.status === 401) {
      if (error.response.data) {
        toast.error(`${error.response.data}`);
      } else {
        toast.error('Invalid login credential, please login again!');
        const currentPath = window.location.pathname;
        if (PAGE_PATH.indexOf(currentPath) === -1 && currentPath !== '/login') {
          window.location.href = '/login';
        }
      }
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  },
);

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  delete: axios.delete,
};
