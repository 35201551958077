import LOCAL_KEY from 'consts/commons';

let customerData = JSON.parse(
  localStorage.getItem(LOCAL_KEY.CUSTOMER) || 'null',
);
if (!customerData || !customerData.id) {
  customerData = { id: '', companyName: '' };
}

let tourData = JSON.parse(localStorage.getItem(LOCAL_KEY.TOUR) || 'null');
if (!tourData || !tourData.id) {
  tourData = { id: '', name: '' };
}

const userId = localStorage.getItem(LOCAL_KEY.USER_ID) || null;

const initialState = {
  ready: false,
  user: {
    id: userId,
    role: null,
  },
  customers: [],
  tours: [],
  isCustomerModalOpen: false,
  isTourModalOpen: false,
  customer: customerData,
  tour: tourData,
  threed: {
    images: [],
    imageType: '', // [canvas, url]
    defaultOrientation: null, // [x, y, z]
    lensFlareLocation: [], // [x, y, z]
    previewImage: null,
  },
  reports: {
    tours: {
      visitedIn30Day: null,
    },
  },
};

export default initialState;
