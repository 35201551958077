import { DATE_FORMAT } from 'consts';
import LOCAL_KEY from 'consts/commons';
import { PATH_NOT_LOAD_TOURS } from 'consts/pathMatch';
import moment from 'moment';
import { put, select } from 'redux-saga/effects';
import { checkToken } from '../services/authorization';
import { getTourDetail, getToursBySearchQuery } from '../services/tours';
import * as ACTION_TYPE from './actionTypes';
import { loadCustomersByRole } from './modules';

const selectTourId = ({ tour }) => (tour && tour.id) || '';
const selectCustomerId = ({ customer }) => (customer && customer.id) || '';
const selectUserAndRole = ({ user }) => user;

function kickStart() {
  return new Promise((resolve) => {
    setTimeout(() => resolve('DONE'), 0);
  });
}

function* initiate() {
  try {
    const result = yield kickStart();
    if (result) {
      yield put({
        type: ACTION_TYPE.INITIATED,
      });
    }
    const currentPath = window.location.pathname;
    if (PATH_NOT_LOAD_TOURS.indexOf(currentPath) !== -1) return;
    const customerId = yield select(selectCustomerId);
    if (customerId) {
      yield put({
        type: ACTION_TYPE.LOAD_TOURS,
      });
    }
  } catch (error) {}
}

const goToLogin = () => {
  if (window.location.pathname !== '/login') {
    window.location.href = '/login';
  }
};

function* checkUserRole() {
  try {
    const uId = localStorage.getItem(LOCAL_KEY.USER_ID);
    const user = yield select(selectUserAndRole);
    if (uId) {
      if (!user.role) {
        const tokenRes = yield checkToken();
        const { role, userId } = tokenRes.data.data;

        if (role && uId === userId) {
          yield put({
            type: ACTION_TYPE.SET_USER,
            user: {
              id: userId,
              role,
            },
          });
        } else {
          return goToLogin();
        }
      }
      yield loadCustomersByRole();
      yield put({ type: ACTION_TYPE.LOAD_TOURS });
    } else {
      goToLogin();
    }
  } catch (error) {
    console.error('Fail to checkUserRole: ', error);
  }
}

function* refreshTourDetail() {
  try {
    const tourId = yield select(selectTourId);
    if (!tourId) {
      return;
    }
    const response = yield getTourDetail(tourId);
    const tour = response.data.data;
    if (tour.id === tourId) {
      yield put({
        type: ACTION_TYPE.SET_TOUR,
        tour: {
          id: tour.id,
          name: tour.name,
          friendlyName: tour.friendlyName,
          isModified: tour.isModified,
        },
      });
    }
  } catch (error) {}
}

function* loadToursForCurrentCustomer() {
  try {
    const customerId = yield select(selectCustomerId);

    if (customerId) {
      const tourResponse = yield getToursBySearchQuery({ customerId });
      const tours = tourResponse.data.data.map((item) => {
        item.updatedAt = item.updatedAt
          ? moment(item.updatedAt).format(DATE_FORMAT)
          : moment(item.createdAt).format(DATE_FORMAT);
        return item;
      });
      yield put({
        type: ACTION_TYPE.SET_TOURS,
        tours,
      });
    } else {
      // Clear current tours
      yield put({
        type: ACTION_TYPE.SET_TOURS,
        tours: [],
      });
    }
  } catch (error) {}
}

const middlewares = {
  initiate,
  refreshTourDetail,
  checkUserRole,
  loadToursForCurrentCustomer,
};

export default middlewares;
