import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import {
  loadTours,
  setCustomer,
  setTour,
  toggleCustomerModal,
  toggleTourModal,
} from '../../store/actions';

const emptyTourState = {
  id: '',
  name: '',
  friendlyName: '',
};

const selectCustomer = {
  id: '',
  companyName: 'Select Customer',
};

class Modals extends Component {
  constructor(props) {
    super();
    this.state = {
      modal: false,
      defaultCustomer: '',
      defaultTour: props.tour.id || '',
      dropDownOpen: false,
      dropDownValue: null,
      customer: {
        id: '',
        companyName: '',
      },
      tour: {
        id: '',
        name: '',
      },
    };
  }

  componentDidMount() {
    this.setState({ defaultCustomer: this.props.customer.id || '' });
  }

  componentDidUpdate(prevProps) {
    const { customer, tours, tour } = this.props;
    if (prevProps.userId !== this.props.userId) {
      this.setState({ defaultCustomer: this.props.customer.id || '' });
    }
    if (prevProps.customer.id !== customer.id) {
      this.props.setTour(emptyTourState);
      if (customer.id) {
        this.props.loadTours();
      }
    }
    if (prevProps.tours !== tours && tours.length === 1) {
      if (!tour || !tour.id || tour.id !== tours[0].id) {
        this.props.setTour(tours[0]);
      }
    }
    if (prevProps.tour.id !== tour.id) {
      this.setState({ defaultTour: tour.id || '' });
    }
  }

  toggle = () => {
    this.setState({
      dropDownOpen: !this.state.dropDownOpen,
    });
  };

  changeValue = (e) => {
    const keyName = e.currentTarget.name;
    this.setState({
      [keyName]: e.currentTarget.value,
    });
  };

  changeTourValue = (e) => {
    const keyName = e.currentTarget.name;
    this.setState({
      [keyName]: e.currentTarget.value,
    });
  };

  findCustomerById(customerId) {
    if (!customerId) return selectCustomer;
    return this.props.customers.find((customer) => customer.id === customerId);
  }

  findTourById(tourId) {
    return this.props.tours.find((tour) => tour.id === tourId);
  }

  handleSubmit = () => {
    const customerId = this.state.defaultCustomer;
    if (!customerId) {
      this.props.setCustomer({
        id: '',
        companyName: '',
      });
      this.props.setTour(emptyTourState);
      this.props.toggleCustomerModal(false);
    } else {
      const customer = this.findCustomerById(customerId);
      customer?.companyName
        ? this.props.setCustomer({
            id: customerId,
            companyName: customer.companyName,
          })
        : this.props.setCustomer({
            id: '',
            companyName: '',
          });

      this.props.toggleCustomerModal(false);
      setTimeout(() => this.props.loadTours(), 100);
      setTimeout(() => this.props.history.push('/tours'), 500);
    }
  };

  handleTourSubmit = () => {
    const tourId = this.state.defaultTour;
    if (!tourId) {
      this.props.setTour(emptyTourState);
    } else {
      const tour = this.findTourById(tourId);
      if (tour) {
        this.props.setTour({
          id: tourId,
          name: tour.name,
          isModified: tour.isModified,
          friendlyName: tour.friendlyName,
        });
      }
    }
    this.props.toggleTourModal(false);
    if (this.props.history.location.pathname === '/dashboard') {
      this.props.history.push('/tours');
    }
  };

  render() {
    const { customers } = this.props;
    return (
      <div>
        <Modal
          isOpen={this.props.isCustomerModalOpen}
          toggle={() => this.props.toggleCustomerModal(false)}
          className={'modal-sm modal-info ' + this.props.className}
        >
          <ModalHeader toggle={() => this.props.toggleCustomerModal(false)}>
            Select Customer
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <Input
                  type="select"
                  name="defaultCustomer"
                  id="default-customer"
                  value={this.state.defaultCustomer}
                  onChange={this.changeValue}
                >
                  <option value="">Select Customer</option>
                  {customers.map((e, index) => (
                    <option value={e.id} key={index}>
                      {e.companyName}
                    </option>
                  ))}
                </Input>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.handleSubmit}>
              Save
            </Button>
            <Button
              color="secondary"
              onClick={() => this.props.toggleCustomerModal(false)}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.props.isTourModalOpen}
          toggle={() => this.props.toggleTourModal(false)}
          className={'modal-sm modal-info ' + this.props.className}
        >
          <ModalHeader toggle={() => this.props.toggleTourModal(false)}>
            Select Tour
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <Input
                  type="select"
                  name="defaultTour"
                  id="default-tour"
                  value={this.state.defaultTour}
                  onChange={this.changeTourValue}
                >
                  {!this.state.defaultTour && this.props.tours.length > 0 ? (
                    <option value="">Select Tour</option>
                  ) : null}
                  {this.props.tours.map((e, index) => (
                    <option value={e.id} key={index}>
                      {e.name}
                    </option>
                  ))}
                </Input>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.handleTourSubmit}>
              Save
            </Button>
            <Button
              color="secondary"
              onClick={() => this.props.toggleTourModal(false)}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const globalData = ({
  isCustomerModalOpen,
  isTourModalOpen,
  customer,
  customers,
  tour,
  tours,
  user,
}) => ({
  isCustomerModalOpen,
  isTourModalOpen,
  customer,
  customers,
  tour,
  tours,
  userId: user.id,
  role: user.role,
});
const mapActionToProps = {
  toggleCustomerModal,
  setCustomer,
  toggleTourModal,
  setTour,
  loadTours,
};

export default connect(globalData, mapActionToProps)(withRouter(Modals));
