import api from '../helpers/api';
import urls from '../consts/urls';

export const getCustomers = () => {
  return api.get(urls.CUSTOMERS);
};

export const getCustomerDetail = (id) => {
  return api.get(urls.CUSTOMERS + '/' + id);
};

export const updateCustomer = (id, data) => {
  if (id) {
    return api.put(urls.CUSTOMERS + '/' + id, data);
  }
  return api.post(urls.CUSTOMERS, data);
};

export const getUserCustomers = (id) => {
  return api.get(urls.USER_CUSTOMERS + '/' + id);
};

export default {
  getCustomers,
  getCustomerDetail,
  updateCustomer,
  getUserCustomers,
};
