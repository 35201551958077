/* eslint-disable default-case */
import LOCAL_KEY from 'consts/commons';
import produce from 'immer';
import * as ACTION_TYPE from './actionTypes';
import initialState from './state';

const appReducer = produce((state, action) => {
  console.log(action);
  switch (action.type) {
    case ACTION_TYPE.INITIATED:
      state.ready = true;
      break;
    case ACTION_TYPE.TOGGLE_CUSTOMER_MODAL:
      state.isCustomerModalOpen = action.isOpen;
      break;
    case ACTION_TYPE.SET_CUSTOMER:
      state.customer = action.customer;
      localStorage.setItem(LOCAL_KEY.CUSTOMER, JSON.stringify(action.customer));
      break;
    case ACTION_TYPE.TOGGLE_TOUR_MODAL:
      state.isTourModalOpen = action.isOpen;
      break;
    case ACTION_TYPE.SET_TOUR:
      state.tour = action.tour;
      localStorage.setItem(LOCAL_KEY.TOUR, JSON.stringify(action.tour));
      break;
    case ACTION_TYPE.SET_TOURS:
      state.tours = action.tours;
      break;
    case ACTION_TYPE.SET_360_DATA:
      state.threed = {
        ...state.threed,
        ...action.data,
      };
      break;
    case ACTION_TYPE.SET_360_IMAGES:
      state.threed.images = [...action.images];
      state.threed.imageType = action.imageType;
      break;
    case ACTION_TYPE.SET_LENSFLARE:
      state.threed.lensFlareLocation = action.lensFlareLocation;
      break;
    case ACTION_TYPE.SET_USER:
      if (action.user.id !== undefined) {
        state.user.id = action.user.id;
      }
      if (action.user.role !== undefined) {
        state.user.role = action.user.role;
      }
      break;
    case ACTION_TYPE.SET_USER_ROLE:
      state.user.role = action.role;
      break;
    case ACTION_TYPE.SET_USER_CUSTOMERS:
      state.customers = action.customers;
      break;
    case ACTION_TYPE.SET_GA_REPORT_TOUR_VISITED_IN_30_DAYS:
      state.reports.tours.visitedIn30Day = action.visited;
      break;
    case ACTION_TYPE.SIGN_OUT:
      state.user.id = '';
      state.user.role = '';
      localStorage.setItem(LOCAL_KEY.USER_ID, '');
      localStorage.setItem(LOCAL_KEY.TOKEN, '');
      break;
  }
}, initialState);

export default appReducer;
