import { put, select } from 'redux-saga/effects';

import { FIRST_POSITION } from 'consts/commons';
import ROLES from 'consts/role';
import { getCustomers, getUserCustomers } from 'services/customers';

import * as ACTION_TYPES from '../actionTypes';

const selectUser = ({ user }) => user;

/**
 * Load All Customers
 * @return {void}
 */
function* loadCustomers() {
  try {
    const res = yield getCustomers();
    yield put({
      type: ACTION_TYPES.SET_USER_CUSTOMERS,
      customers: res.data.data,
    });
  } catch (error) {
    console.error('Fail to load Customers: ', error?.message);
  }
}

/**
 * Load UserCustomer for Customer
 * @param {string} userId UserId
 * @return {void}
 */
function* loadUserCustomers(userId) {
  try {
    const res = yield getUserCustomers(userId);
    const customers = res.data.data;
    if (customers && customers.length !== 0) {
      yield put({
        type: ACTION_TYPES.SET_USER_CUSTOMERS,
        customers: res.data.data,
      });
      const { id, companyName } = customers[FIRST_POSITION];
      yield put({
        type: ACTION_TYPES.SET_CUSTOMER,
        customer: { id, companyName },
      });
    }
  } catch (error) {
    console.error('Fail to load UserCustomers: ', error?.message);
  }
}

/**
 * Load List Customers for Current logged in User
 * @param {boolean} isCustomer is current user's role is Customer
 * @param {Object} user current logged in user
 * @return {void}
 */

export function* loadCustomersByRole() {
  const { id, role } = yield select(selectUser);

  if (role === ROLES.CUSTOMER) {
    // Case: Logged User has role CUSTOMER
    yield loadUserCustomers(id);
  } else {
    // Case: Logged User has role ADMIN || SUB_ADMIN
    yield loadCustomers();
  }
}
