import api from '../helpers/api';
import URL from '../consts/urls';

export const logIn = (data) => {
  return api.post(URL.LOGIN, data);
};

export const checkToken = () => api.post(URL.CHECK_TOKEN, {});

export default {
  logIn,
  checkToken,
};
