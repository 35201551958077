export const INITIATE = 'INITIATE';
export const INITIATED = 'INITIATED';
export const TOGGLE_CUSTOMER_MODAL = 'TOGGLE_CUSTOMER_MODAL';
export const TOGGLE_TOUR_MODAL = 'TOGGLE_TOUR_MODAL';
export const REFRESH_TOUR_DETAIL = 'REFRESH_TOUR_DETAIL';
export const SET_CUSTOMER = 'SET_CUSTOMER';
export const SET_TOUR = 'SET_TOUR';
export const LOAD_TOURS = 'LOAD_TOURS';
export const SET_TOURS = 'SET_TOURS';
export const SET_360_DATA = 'SET_360_DATA';
export const SET_360_IMAGES = 'SET_360_IMAGES';
export const SET_LENSFLARE = 'SET_LENSFLARE';
export const SET_USER = 'SET_USER';
export const CHECK_USER_ROLE = 'CHECK_USER_ROLE';
export const SET_USER_ROLE = 'SET_USER_ROLE';
export const SET_USER_CUSTOMERS = 'SET_USER_CUSTOMERS';
export const REFRESH_CUSTOMERS = 'REFRESH_CUSTOMERS';
export const SET_GA_REPORT_TOUR_VISITED_IN_30_DAYS =
  'SET_GA_REPORT_TOUR_VISITED_IN_30_DAYS';
export const SIGN_OUT = 'SIGN_OUT';
