import api from '../helpers/api';
import urls from '../consts/urls';

export const getToursBySearchQuery = (data) => {
  return api.patch(urls.TOURS, data);
};

export const getTourDetail = (tourId) => {
  return api.get(urls.TOURS + '/' + tourId);
};

export const updateTour = (id, data) => {
  if (id) {
    return api.put(urls.TOURS + '/' + id, data);
  }
  return api.post(urls.TOURS, data);
};

export const getTourJson = (id) => {
  return api.get(`${urls.TOURS}/${id}/json`);
};

export const generateTourJson = (id) => {
  return api.post(`${urls.TOURS}/${id}/json`);
};

export const publishTourJson = (jsonId) => {
  return api.post(`${urls.TOURS}/${jsonId}/publish`);
};

export const softDelete = (items = []) => {
  return api.post(`${urls.TOURS}/SoftDelete`, { items });
};

export const copyTour = (id, data) => {
  return api.post(urls.TOURS + '/' + id + '/copy', data);
};

export const getToursJons = (tours = [], isPublished = true) => {
  return api.patch(urls.TOURS + '/json', { tours, isPublished });
};

export default {
  getToursBySearchQuery,
  getTourDetail,
  updateTour,
  getTourJson,
  generateTourJson,
  publishTourJson,
  softDelete,
  copyTour,
  getToursJons,
};
