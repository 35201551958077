const BASE_API_URL = process.env.REACT_APP_API_URL;
const GOOGLE_API_URL = 'https://analyticsreporting.googleapis.com/v4/';

export default {
  USERS: BASE_API_URL + 'Users',
  USER_CUSTOMERS: BASE_API_URL + 'Users/Customers',
  CUSTOMERS: BASE_API_URL + 'Customers',
  UPLOAD_IMAGE: BASE_API_URL + 'Images/Upload',
  UPLOAD_FILE: BASE_API_URL + 'Files/Upload',
  UPLOAD_SCENE_IMAGE: BASE_API_URL + 'Images/UploadScene',
  GET_PROCESSED_IMAGES: BASE_API_URL + 'Images/ProcessedImages',
  IMAGES: BASE_API_URL + 'Images',
  LOGIN: BASE_API_URL + 'Auth/Login',
  CHECK_TOKEN: BASE_API_URL + 'Auth/CheckToken',
  TOURS: BASE_API_URL + 'Tours',
  SCENES: BASE_API_URL + 'Scenes',
  MEDIAS: BASE_API_URL + 'Medias',
  HOTSPOTS: BASE_API_URL + 'Hotspots',
  MENU: BASE_API_URL + 'Menu',
  TOUR_GROUPS: BASE_API_URL + 'TourGroups',
  MEDIA_GROUPS: BASE_API_URL + 'MediaGroup',
  FEATURED_MEDIA: BASE_API_URL + 'FeaturedMedia',
  SCENE_VARIANTS: BASE_API_URL + 'SceneVariants',
  BATCH_GET_REPORT: GOOGLE_API_URL + 'reports:batchGet',
  GET_GOOGLE_TOKEN: BASE_API_URL + 'Auth/ga-token',
};
