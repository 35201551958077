import * as ACTION_TYPE from './actionTypes';

export const initiate = () => ({ type: ACTION_TYPE.INITIATE });
export const toggleCustomerModal = (isOpen) => ({
  type: ACTION_TYPE.TOGGLE_CUSTOMER_MODAL,
  isOpen,
});

export const setCustomer = (customer) => ({
  type: ACTION_TYPE.SET_CUSTOMER,
  customer,
});

export const toggleTourModal = (isOpen) => ({
  type: ACTION_TYPE.TOGGLE_TOUR_MODAL,
  isOpen,
});

export const setTour = (tour) => ({
  type: ACTION_TYPE.SET_TOUR,
  tour,
});

export const loadTours = () => ({
  type: ACTION_TYPE.LOAD_TOURS,
});

export const setTours = (tours) => ({
  type: ACTION_TYPE.SET_TOURS,
  tours,
});

export const set360Data = (data) => ({
  type: ACTION_TYPE.SET_360_DATA,
  data,
});

export const set360Images = (images, imageType) => ({
  type: ACTION_TYPE.SET_360_IMAGES,
  images,
  imageType,
});

export const setLensflare = (lensflare) => ({
  type: ACTION_TYPE.SET_LENSFLARE,
  lensFlareLocation: lensflare,
});

export const refreshTourDetail = () => ({
  type: ACTION_TYPE.REFRESH_TOUR_DETAIL,
});

export const logout = () => ({
  type: ACTION_TYPE.SIGN_OUT,
});

export const setUser = (user) => ({
  type: ACTION_TYPE.SET_USER,
  user,
});

export const checkUserRole = () => ({
  type: ACTION_TYPE.CHECK_USER_ROLE,
});

export const setUserRole = (role) => ({
  type: ACTION_TYPE.SET_USER_ROLE,
  role,
});

export const setUserCustomers = (customers) => ({
  type: ACTION_TYPE.SET_USER_CUSTOMERS,
  customers,
});

export const refreshCustomers = () => ({
  type: ACTION_TYPE.REFRESH_CUSTOMERS,
});

export const setTourVisitedIn30Days = ({ visited }) => ({
  type: ACTION_TYPE.SET_GA_REPORT_TOUR_VISITED_IN_30_DAYS,
  visited,
});
