export const DISPLAY_ROLES = {
  Admin: 'Admin',
  SubAdmin: 'Sub Admin',
  Customer: 'Customer',
};
export default {
  ADMIN: 'Admin',
  SUB_ADMIN: 'SubAdmin',
  CUSTOMER: 'Customer',
};
